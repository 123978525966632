"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.splitAndValidate = void 0;
const constants_1 = require("./constants");
/**
 * Splits the classname and return an array of
 * valid picasso class types
 * @param {String} classname
 */
exports.splitAndValidate = (classname, componentType, theme) => {
    const validProperties = [
        ...constants_1.validPropertiesBase,
        ...constants_1.validPropertiesForType[componentType],
        ...Object.keys(theme),
    ];
    const split = classname.split(' ');
    return split
        .map((declaration) => {
        const [property, value] = declaration.split('-');
        if (validProperties.includes(property)) {
            const final = { property, value };
            return final;
        }
        return { property: '', value: '' };
    })
        .filter(Boolean);
};
