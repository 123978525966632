"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_native_1 = require("react-native");
const style_helpers_1 = require("../util/style-helpers");
const theming_1 = require("../core/theming");
const PicassoSafeView = React.forwardRef((props, ref) => {
    const { children, className = '', style } = props, others = __rest(props, ["children", "className", "style"]);
    return (<theming_1.ThemeContext.Consumer>
      {(theme) => {
        var _a;
        const picassoStyle = style_helpers_1.buildStyleSheet(className, theme, 'view');
        return (<react_native_1.SafeAreaView ref={ref} style={[{ borderColor: (_a = theme.colors) === null || _a === void 0 ? void 0 : _a.border }, picassoStyle, style]} {...others}>
            {children}
          </react_native_1.SafeAreaView>);
    }}
    </theming_1.ThemeContext.Consumer>);
});
PicassoSafeView.displayName = 'PicassoSafeView';
exports.default = PicassoSafeView;
