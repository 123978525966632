"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildStyleSheet = void 0;
/* eslint-disable newline-after-var */
const react_native_1 = require("react-native");
const classname_helpers_1 = require("./classname-helpers");
const constants_1 = require("./constants");
const getSide = (value) => {
    let side = '';
    if (value === 't')
        side = 'Top';
    else if (value === 'b')
        side = 'Bottom';
    else if (value === 'l')
        side = 'Left';
    else if (value === 'r')
        side = 'Right';
    else if (value === 'x')
        side = 'Horizontal';
    else if (value === 'y')
        side = 'Vertical';
    return side;
};
const classesToStyle = (array, theme) => {
    let styles = {};
    array.forEach(({ property, value }) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        switch (property.toLowerCase()) {
            case constants_1.PROPERTIES.BACKGROUND:
                styles.backgroundColor = theme.colors[value];
                break;
            case constants_1.PROPERTIES.COLOR:
            case constants_1.PROPERTIES.COLOR_SHORT:
                styles.color = theme.textColors[value];
                break;
            case constants_1.PROPERTIES.FONT_SIZE:
            case constants_1.PROPERTIES.FONT_SIZE_SHORT:
                styles.fontSize = (_b = (_a = theme.font) === null || _a === void 0 ? void 0 : _a.sizes) === null || _b === void 0 ? void 0 : _b[value];
                break;
            case constants_1.PROPERTIES.FONT_WEIGHT:
            case constants_1.PROPERTIES.FONT_WEIGHT_SHORT:
                styles.fontWeight = (_d = (_c = theme.font) === null || _c === void 0 ? void 0 : _c.weights) === null || _d === void 0 ? void 0 : _d[value];
                break;
            case constants_1.PROPERTIES.ALIGN_ITEMS:
            case constants_1.PROPERTIES.ALIGN_ITEMS_SHORT:
                styles.alignItems = constants_1.alignItemsValues[value];
                break;
            case constants_1.PROPERTIES.JUSTIFY_CONTENT:
            case constants_1.PROPERTIES.JUSTIFY_CONTENT_SHORT:
                styles.justifyContent = constants_1.alignItemsValues[value];
                break;
            case constants_1.PROPERTIES.ALIGN_SELF:
            case constants_1.PROPERTIES.ALIGN_SELF_SHORT:
                styles.alignSelf = constants_1.alignItemsValues[value];
                break;
            case constants_1.PROPERTIES.TEXT_ALIGN:
            case constants_1.PROPERTIES.TEXT_ALIGN_SHORT:
                styles.textAlign = constants_1.alignValues[value];
                break;
            case constants_1.PROPERTIES.FLEX:
            case constants_1.PROPERTIES.FLEX_SHORT:
                if (constants_1.flexValues[value]) {
                    styles.flexDirection = constants_1.flexValues[value];
                }
                else if (!Number.isNaN(Number(value))) {
                    styles.flex = Number(value);
                }
                break;
            case constants_1.PROPERTIES.BORDER_RADIUS:
            case constants_1.PROPERTIES.BORDER_RADIUS_SHORT:
                styles.borderRadius = (_e = theme.radius) === null || _e === void 0 ? void 0 : _e[value];
                break;
            case constants_1.PROPERTIES.BORDER_RADIUS_BOTTOM_LEFT:
            case constants_1.PROPERTIES.BORDER_RADIUS_BOTTOM_LEFT_SHORT:
                styles.borderBottomLeftRadius = (_f = theme.radius) === null || _f === void 0 ? void 0 : _f[value];
                break;
            case constants_1.PROPERTIES.BORDER_RADIUS_BOTTOM_RIGHT:
            case constants_1.PROPERTIES.BORDER_RADIUS_BOTTOM_RIGHT_SHORT:
                styles.borderBottomRightRadius = (_g = theme.radius) === null || _g === void 0 ? void 0 : _g[value];
                break;
            case constants_1.PROPERTIES.BORDER_RADIUS_TOP_LEFT:
            case constants_1.PROPERTIES.BORDER_RADIUS_TOP_LEFT_SHORT:
                styles.borderTopLeftRadius = (_h = theme.radius) === null || _h === void 0 ? void 0 : _h[value];
                break;
            case constants_1.PROPERTIES.BORDER_RADIUS_TOP_RIGHT:
            case constants_1.PROPERTIES.BORDER_RADIUS_TOP_RIGHT_SHORT:
                styles.borderTopRightRadius = (_j = theme.radius) === null || _j === void 0 ? void 0 : _j[value];
                break;
            case constants_1.PROPERTIES.BORDER_COLOR:
            case constants_1.PROPERTIES.BORDER_COLOR_SHORT:
                styles.borderColor = (_k = theme.colors) === null || _k === void 0 ? void 0 : _k[value];
                break;
            case constants_1.PROPERTIES.FONT_STYLE:
            case constants_1.PROPERTIES.FONT_STYLE_SHORT:
                styles.fontStyle = constants_1.fontStyleValues[value];
                break;
            default:
                if (constants_1.spacingProperties.includes(property.toLowerCase())) {
                    const values = theme.spacing;
                    let main = '';
                    const side = getSide(property[1]);
                    if (property[0] === 'm')
                        main = 'margin';
                    else if (property[0] === 'p')
                        main = 'padding';
                    styles[`${main}${side}`] = values[value];
                }
                else if (constants_1.borderWidthProperties.includes(property.toLowerCase())) {
                    const prop = property[1];
                    const side = getSide(prop);
                    if (!Number.isNaN(Number(value))) {
                        styles[`border${side}Width`] = Number(value);
                    }
                }
                // Custom root values like elevated
                else {
                    styles = Object.assign(Object.assign({}, styles), theme[property]);
                }
        }
    });
    return styles;
};
exports.buildStyleSheet = (className, theme, componentType = 'custom') => {
    const arrayClasses = classname_helpers_1.splitAndValidate(className, componentType, theme);
    return react_native_1.StyleSheet.flatten(classesToStyle(arrayClasses, theme));
};
